import { APIErrorProvider } from "./dialogs/error/APIErrorProvider";
import { AuthProvider, AuthProviderProps } from "oidc-react";
import { CurrentFolderProvider } from "./contexts/CurrentFolderProvider";
import { IAppConfig } from "./api/restModel";
import { IntlProvider } from "react-intl";
import { LoadingIndicatorProvider } from "./contexts/LoadingIndicatorProvider";
import { SnackbarProvider } from "notistack";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { axiosInstance, getAppConfig } from "./api/restFacade";
import { determineAPIUrl } from "./utils/environmentUtils";
import { makeStyles } from "@mui/styles";
import { zebStyles as parentZebStyles } from "./zebstyles";
import { useNavigate } from "react-router";
import CssBaseline from "@material-ui/core/CssBaseline";
import ErrorDialog from "./dialogs/error/ErrorDialog";
import MainView from "./components/MainView";
import React, { useEffect, useRef, useState } from "react";
import messagesDE from "./i18n/de/messages.json";
import messagesEN from "./i18n/en/messages.json";
import zebTheme from "./theme/zebTheme";

const theme = createTheme();
const useStyles = makeStyles(() => ({
  ...parentZebStyles(theme),
  outerdiv: {
    height: "100%",
  },
  panel: {
    position: "absolute",
    left: "50%",
    top: "50%",
    marginLeft: "-200px",
    marginTop: "-200px",
    width: "400px",
    height: "400px",
    textAlign: "center",
    backgroundColor: theme.palette.background.paper,
    fontSize: "12px",
    fontFamily: "Open Sans,Helvetica,Arial,sans-serif",
    lineHeight: "1.66666667",
    color: theme.palette.text.secondary,
  },
}));

const POST_LOGOUT_REDIRECT_URI = "https://zeb-control.com/de-DE";

// load localization files (client-side)
const messages: { [key: string]: any } = {
  de: messagesDE,
  en: messagesEN,
};

const currentLanguage = navigator.language.split(/[-_]/)[0];

export default function App(): React.ReactElement {
  const [authProviderConfig, setAuthProviderConfig] = useState<AuthProviderProps | null>(null);
  const classes = useStyles();
  const navigate = useNavigate();

  const redirectUri = useRef<string>(`${window.location.origin}/`);

  useEffect(() => {
    getAppConfig()
      .then((result) => {
        // set baseUrl for all further calls to api-prefix from app config endpoint
        axiosInstance.defaults.baseURL = `${determineAPIUrl(window.location)}${result?.["api-path-prefix"] ?? ""}`;

        const config = buildAuthProviderConfig(result);
        setAuthProviderConfig(config);
      })
      .catch((err) => {
        console.error("Error while trying to configure authentication provider.", JSON.stringify(err));

        console.debug("Performing second attempt on legacy api baseUrl");
        axiosInstance.defaults.baseURL = determineAPIUrl(window.location, true);

        getAppConfig()
          .then((result) => {
            const config = buildAuthProviderConfig(result);
            setAuthProviderConfig(config);
          })
          .catch((err) => {
            console.error("Error while trying to configure authentication provider with legacy api.", JSON.stringify(err));
          });
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const buildAuthProviderConfig = (appConfig: IAppConfig | null): AuthProviderProps => {
    const authority = appConfig?.["idp-well-known-configuration"].includes(".well-known/openid-configuration")
      ? appConfig["idp-well-known-configuration"].split(".well-known/openid-configuration")[0]
      : "";

    const authProviderConfig: AuthProviderProps = {
      authority: authority,
      clientId: appConfig ? appConfig["client-id"] : "",
      scope: "profile email openid",
      responseType: "code",
      redirectUri: redirectUri.current,
      autoSignIn: true,
      automaticSilentRenew: true,
      postLogoutRedirectUri: POST_LOGOUT_REDIRECT_URI,
    };
    return authProviderConfig;
  };

  const onSignInCallback = () => {
    navigate("/");
  };

  return (
    <>
      <IntlProvider
        locale={currentLanguage}
        messages={messages[currentLanguage] ? messages[currentLanguage] : messages["en"]}>
        {authProviderConfig ? (
          <AuthProvider {...authProviderConfig} onSignIn={onSignInCallback}>
            <div className={classes.outerdiv}>
              <SnackbarProvider maxSnack={3}>
                <ThemeProvider theme={zebTheme}>
                  <CssBaseline />
                  <APIErrorProvider>
                    <LoadingIndicatorProvider>
                      <CurrentFolderProvider>
                        <MainView />
                      </CurrentFolderProvider>
                      <ErrorDialog />
                    </LoadingIndicatorProvider>
                  </APIErrorProvider>
                </ThemeProvider>
              </SnackbarProvider>
            </div>
          </AuthProvider>
        ) : null}
      </IntlProvider>
    </>
  );
}
