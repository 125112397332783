import { CircularProgress, Tooltip } from "@mui/material";
import { FolderStructureResponseDto, S3Object } from "../../api/restModel";
import { descendingStringComparator } from "../../utils/sortationUtils";
import { formatErrorToMessageId } from "../../api/errorUtil";
import { getFolders } from "../../api/restFacade";
import { useAuth } from "oidc-react";
import { useIntl } from "react-intl";
import Box from "@mui/material/Box";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import FolderIcon from "@mui/icons-material/Folder";
import FolderOpenIcon from "@mui/icons-material/FolderOpen";
import LastPageIcon from "@mui/icons-material/LastPage";
import React, { ReactElement, useEffect, useState } from "react";
import TreeItem from "@mui/lab/TreeItem";
import TreeView from "@mui/lab/TreeView";
import useAPIError from "../../dialogs/error/useAPIError";
import useCurrentFolder from "../../contexts/useCurrentFolder";

interface FolderTreeProps {
  currentFolder: string;
  setCurrentFolder(currentFolder: string): void;
  s3Key: string;
  folderName: string;
  folderMap: Map<string, S3Object[]>;
  updateFolderMap(key: string, value: S3Object[]): void;
}

export default function FolderTree(props: FolderTreeProps) {
  const [childNodes, setChildNodes] = useState<ReactElement[] | null>(null);
  const [expanded, setExpanded] = useState<string[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const { setCurrentFolderS3Object } = useCurrentFolder();
  const { addError } = useAPIError();
  const { formatMessage } = useIntl();
  const auth = useAuth();

  function fetchChildNodes(newCurrentFolder: string): Promise<FolderStructureResponseDto | null | undefined> {
    return getFolders(auth, newCurrentFolder)
      .then((data) => {
        if (data !== null) {
          setCurrentFolderS3Object(newCurrentFolder, data.currentFolder);
          return data;
        }
      })
      .catch((err) => {
        addError(formatMessage({ id: formatErrorToMessageId(err) }, { error: JSON.stringify(err) }), "error");
        setIsLoading(false);
        return null;
      });
  }

  const onNodeSelect = (event: React.SyntheticEvent | undefined, nodeIds: string) => {
    props.setCurrentFolder(nodeIds);
  };

  const handleChange = (event: React.SyntheticEvent | undefined, nodes: string[]) => {
    const expandingNodes = nodes.filter((x) => !expanded.includes(x));
    setExpanded(expandingNodes);
    const newCurrentFolder = props.s3Key;
    if (expandingNodes[0] !== undefined) {
      setIsLoading(true);
      fetchChildNodes(newCurrentFolder === "/" ? "" : newCurrentFolder).then((result) => {
        if (result) {
          props.updateFolderMap(newCurrentFolder, result.content);
        }
        setIsLoading(false);
      });
    }
  };

  useEffect(() => {
    if (props.folderMap.get(props.s3Key)) {
      const list = props.folderMap
        .get(props.s3Key)
        ?.sort((a, b) => descendingStringComparator(a, b, "s3Key"))
        ?.filter((s3Content) => !s3Content.isFile)
        .map((node) => (
          <FolderTree
            {...node}
            currentFolder={props.currentFolder}
            setCurrentFolder={props.setCurrentFolder}
            folderName={node.name + "/"}
            key={node.s3Key}
            data-testid={node.s3Key}
            folderMap={props.folderMap}
            updateFolderMap={props.updateFolderMap}
          />
        ));
      if (list) {
        setChildNodes(list);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.folderMap, props.currentFolder]);

  useEffect(() => {
    //  load the first folder automatically
    if (props.s3Key === "/" && expanded.length === 0 && props.folderMap.size === 0) {
      handleChange(undefined, ["/"]);
    }
  });

  return (
    <TreeView
      aria-label="icon expansion"
      defaultCollapseIcon={
        <>
          <ChevronRightIcon />
          <FolderOpenIcon htmlColor="#fdb900" />
        </>
      }
      defaultExpandIcon={
        <>
          <ChevronRightIcon />
          <FolderIcon htmlColor="#fdb900" />
        </>
      }
      defaultEndIcon={
        <>
          <LastPageIcon />
          <FolderOpenIcon htmlColor="#fdb900" />
        </>
      }
      expanded={expanded}
      onNodeToggle={handleChange}
      onNodeSelect={onNodeSelect}
      selected={props.currentFolder}>
      <TreeItem
        nodeId={props.s3Key}
        data-testid={props.s3Key}
        label={
          <Tooltip title={props.folderName} enterDelay={1000} enterTouchDelay={1000}>
            <Box sx={{ ml: "0.75rem" }}>
              <>
                {props.folderName} {isLoading && <CircularProgress size="1rem" />}
              </>
            </Box>
          </Tooltip>
        }>
        {childNodes || [<div key="stub" />]}
      </TreeItem>
    </TreeView>
  );
}
